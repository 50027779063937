/**
 * Returns last item in path ("/first/second/last") with optional typing
 */
export function lastInPath<T extends string>(path: string): T {
  if (!path.includes('/')) {
    return path as T
  }
  if (path.endsWith('/')) {
    path = path.substring(0, path.length - 1) as T
  }
  const parts = path.split('/')
  return parts[parts.length - 1] as T
}

export function isString(value: any): value is string {
  return typeof value === 'string'
}

export function ensureStartsWith(text: string, char: string): string {
  if (text.charAt(0) !== char) {
    return `${char}${text}`
  }
  return text
}

export function richTextIsEmpty(richText: string | undefined): boolean {
  if (!richText) {
    return true
  }
  return richText === '<?xml version="1.0" encoding="UTF-8"?>\n'
}

/**
 * Converts a delimited string to camelCase.
 *
 * @param input - The input string in a delimited format (e.g., kebab-case, snake_case).
 * @param delimiter - The delimiter used in the input string (default is '-').
 * @returns The camelCase version of the input string.
 */
export function toCamelCase(input: string, delimiter: string = '-'): string {
  const regex = new RegExp(`${delimiter}([a-zA-Z])`, 'g')
  return input.replace(regex, (_, char) => char.toUpperCase())
}

/**
 * Replaces variables in a string with their values
 *
 * @param raw - a string potentially containing variables in the form of `{{ variableKey }}`
 * @param variables - an object containing the values to replace the variables with, i.e. `{ variableKey: 'value' }`
 *
 * @returns the string with the variables replaced, or the original string if no variables were found
 */
export function interpolate<T extends string | undefined | null>(raw: T, variables: Record<string, string | number> | undefined): T {
  if (!raw || !variables) {
    return raw
  }

  // @ts-expect-error I don't think this is possible to type correctly
  return raw.replace(/\{\{\s*(\w+)\s*\}\}/g, (_, key) => {
    return key in variables ? variables[key] : `{{${key}}}`
  }) as T
}
